/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/API';

function RelatedIntByDest({
    destination,
    destinationId,
    listOnly = false,
}) {
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);
    const fetch = (agent) => {
        setIsFetching(true);
        API.fetchRelatedInterestByDest({
            destination: destination || '',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setIsFetching(false);
        });
    };
    const IMAGE_DIR = '/images/interestIcons/'; // Base directory for images

    const interestImages = {
        'accessible travel': 'AccessibleTravel.webp',
        'adult only': 'AdultsOnly.webp',
        'adventure': 'Adventure.webp',
        'all inclusive': 'AllInclusive.webp',
        'animals & wildlife': 'Wildlife.webp',
        'archaeology': 'Archeology.webp',
        'arts & culture': 'ArtsCulture.webp',
        'beach vacations': 'Beach.webp',
        'couples & romance': 'Couples.webp',
        'cruises': 'Cruise.webp',
        'destination weddings': 'Wedding.webp',
        'disney vacations': 'Disney.webp',
        'escorted tours': 'EscortedTours.webp',
        'expedition cruises': 'ExpeditionCruise.webp',
        'family vacations': 'Family.webp',
        'fishing & hunting': 'FishAndHunt.webp',
        'food & wine': 'Food.webp',
        'golf': 'Golf.webp',
        'groups': 'Group.webp',
        'hiking & backpacking': 'Hiking.webp',
        'honeymoons': 'Honeymoon.webp',
        'luxury': 'Luxury.webp',
        'national parks': 'NatParks.webp',
        'nature': 'Nature.webp',
        'overwater bungalows': 'Overwater.webp',
        'religious': 'Religion.webp',
        'river cruises': 'RiverCruise.webp',
        'safari': 'Safari.webp',
        'shopping': 'Shopping.webp',
        'singles': 'Singles.webp',
        'skiing & winter sports': 'WinterSports.webp',
        'water sports': 'WaterSports.webp',
        'wellness': 'Wellness.webp',
    };



    useEffect(() => {
        fetch();
    }, [destination]);


    return (
        <>
            {data.length > 0 && (
                <>
                    {listOnly ? (
                        <div className="py-2">
                            <ToastContainer />
                            <div className="text-xl font-bold">{`Related Interests for ${destination}`}</div>
                            <div className="flex flex-col">
                                <div className="flex flex-col items-start text-base">
                                    {data.map((x) => (
                                        <div
                                            key={x.interestId}
                                            className="cursor-pointer font-normal"
                                        >
                                            <Link to={`/agents?slctInterest=${encodeURIComponent(x.interest)}&slctDestination=${encodeURIComponent(destination)}&destinationId=${destinationId}`}>
                                                {`${x.interest} Travel Agents for ${destination}`}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {data.map((x) => {
                        // Convert interest to lowercase for case-insensitive lookup
                        const imageFilename = interestImages[x.interest.toLowerCase()] || ''; // Default to ''
                        const imageSrc = imageFilename ? `${IMAGE_DIR}${imageFilename}` : '';

                        return (
                            <div key={x.interestId} className="grow-0 text-center whitespace-normal cursor-pointer tl-blue-dark hover:text-red-500">
                                <Link
                                    to={`/agents?slctInterest=${encodeURIComponent(x.interest)}&slctDestination=${encodeURIComponent(destination)}&destinationId=${destinationId}`}
                                >
                                    {imageSrc ? (
                                        <img
                                            src={imageSrc}
                                            alt={x.interest}
                                            className="mx-auto object-cover w-8 filter-dark-blue"
                                        />
                                    ) : (
                                            <div className="w-8 h-8" />
                                        )}
                                    <span className="mt-2 leading-tight block">{x.interest}</span>
                                </Link>
                            </div>
                        );
                    })}
                        </>

                        )}
                </>
            )}
        </>
    );
}

export default RelatedIntByDest;
