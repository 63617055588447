/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable  no-script-url */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { TLLogo } from '../utils';
import SocialIcon from './elements/SocialIcon';

function Footer() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [hideNav, setHideNav] = useState(query.get('nav') || '');
    const [preview, setPreview] = useState(query.get('preview') || 'false');
    let displayNav = hideNav;
    const navCookie = new Cookies();
    // Check/Use Nav if in param/cookie
    if ((displayNav === '0') && (preview === 'false')) {
        navCookie.set('hideNav', '0',
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }
    //set cookie for 2 minutes when PREVIEW is used
    if ((displayNav === '0') && (preview === 'true')) {
        navCookie.set('hideNav', '0',
            {
                expires: new Date(Date.now() + (2 * 60 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }
    // unset cookie if nav=1, shows navigation
    if (displayNav === '1') {
        navCookie.remove('hideNav', { path: '/', sameSite: 'lax' });
    }

    if (navCookie.get('hideNav')) {
        displayNav = navCookie.get('hideNav');
    }
    const leftNavigation = [
        {
            name: 'About Travel Leaders',
            link: '/about_us',
            target: '_self',
        },
        {
            name: 'News Releases',
            link: 'https://internova.com/category/travel-leaders-group/',
            target: '_blank',
        },
        {
            name: 'Contact Us',
            link: 'contact_us',
            target: '_self',
        },
    ];
    const rightNavigation = [
        {
            name: 'Careers',
            link: 'https://www.linkedin.com/company/internova-travel-group/jobs/',
            target: '_blank',
        },
        {
            name: 'For Agents',
            link: 'https://www.agentuniverse.com/',
            target: '_blank',
        },
        {
            name: 'Terms',
            link: 'https://internova.com/terms-conditions/',
            target: '_blank',
        },
        {
            name: 'Privacy',
            link: 'https://internova.com/privacy-policy/',
            target: '_blank',
        },
    ];

    if (displayNav === '0') {
        return '';
    }
    return (
        <div>

            <div className="flex items-end w-full  bg-white relative z-50">

                <div className="w-full text-white">
                    <div className="flex flex-col flex-wrap mx-auto  items-center">
                        <div className="flex flex-col items-center bg-footer-slate w-full pt-20 pb-8 px-4">
                            <div>
                                <a className="flex items-center justify-center font-medium text-gray-400 title-font md:justify-start">
                                    <img alt="Travel Leaders Logo" src={TLLogo} style={{ WebkitFilter: 'brightness(0) invert(100%)', filter: 'brightness(0) invert(100%)' }} loading="lazy" />
                                </a>
                            </div>
                            <div className="mx-auto lg:grid grid-cols-5 mt-2 gap-2 items-center justify-center text-center">
                                <div className="col-span-2 md:order-1 justify-self-center">
                                    <ul className="md:flex uppercase list-none p-0 m-0">
                                        {leftNavigation.map((x, index) => (
                                            <li className="" key={x.name}>
                                                <a href={x.link} target={x.target} className={['text-sm border-gray-500 text-white mx-auto px-2 hover:text-gray-400', index + 1 !== leftNavigation.length ? ' md:border-r-2' : ''].join(' ')}>{x.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-span-2 md:order-3 justify-self-center">
                                    <ul className="md:flex uppercase list-none p-0 m-0">
                                        {rightNavigation.map((x, index) => (
                                            <li className="" key={x.name}>
                                                <a href={x.link} target={x.target} className={['text-sm border-gray-500 text-white mx-auto px-2 hover:text-gray-400', index + 1 !== rightNavigation.length ? ' md:border-r-2' : ''].join(' ')}>{x.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-span-1 md:order-2 my-4 md:my-0 justify-self-center">
                                    <div className="flex flex-row gap-1 sm:gap-2 md:gap-2 lg:gap-4 xl:gap-8  items-center justify-center">
                                        {/*Facebook*/}
                                        <SocialIcon
                                            iconType="fa-facebook-square"
                                            iconSize="fa-lg"
                                            iconURL="https://www.facebook.com/travelleaders/"
                                            label="Facebook"
                                        />
                                        {/*Twitter*/}
                                        <SocialIcon
                                            iconType="fa-square-x-twitter"
                                            iconSize="fa-lg"
                                            iconURL="https://x.com/travelleaders"
                                            label="X formerly known as Twitter"
                                        />
                                        {/*Instagram*/}
                                        <SocialIcon
                                            iconType="fa-instagram"
                                            iconSize="fa-lg"
                                            iconURL="https://www.instagram.com/travelleaders"
                                            label="Instagram"
                                        />
                                        {/*Linked In*/}
                                        <SocialIcon
                                            iconType="fa-linkedin"
                                            iconSize="fa-lg"
                                            iconURL="https://www.linkedin.com/company/travel-leaders-group/"
                                            label="LinkedIn"
                                        />
                                        {/*YouTube*/}
                                        <SocialIcon
                                            iconType="fa-youtube"
                                            iconSize="fa-lg"
                                            iconURL="https://www.youtube.com/travelleaders"
                                            label="YouTube"
                                        />
                                        {/*Pinterest*/}
                                        <SocialIcon
                                            iconType="fa-pinterest-square"
                                            iconSize="fa-lg"
                                            iconURL="https://www.pinterest.com/TravelLeaders/"
                                            label="Pinterest"
                                        />
                                        {/*TikTok*/}
                                        <SocialIcon
                                            iconType="fa-tiktok"
                                            iconSize="fa-lg"
                                            iconURL="https://www.tiktok.com/@travelleadersnetwork"
                                            label="TikTok"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-900 w-full px-2 py-10 full-length text-sm md:text-xs">
                            <div className="md:grid grid-cols-4 gap-1 justify-items-center text-center md:text-left px-2">
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide text-sm">Agents by Metro Area</h3>
                                    <div className="md:grid grid-cols-2 gap-2">
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Atlanta&slctState=GA" className="hover:text-red-600">Atlanta Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Boston&slctState=MA" className="hover:text-red-600">Boston Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Calgary&slctState=AB" className="hover:text-red-600">Calgary Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Chicago&slctState=IL" className="hover:text-red-600">Chicago Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Cincinnati&slctState=OH" className="hover:text-red-600">Cincinnati Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Dallas-Ft.+Worth&slctState=TX" className="hover:text-red-600">Dallas Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Denver&slctState=CO" className="hover:text-red-600">Denver Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Edmonton&slctState=AB" className="hover:text-red-600">Edmonton Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Houston&slctState=TX" className="hover:text-red-600">Houston Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Indianapolis&slctState=IN" className="hover:text-red-600">Indianapolis Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Kansas+City&slctState=KS" className="hover:text-red-600">Kansas City Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Los+Angeles&slctState=CA" className="hover:text-red-600">Los Angeles Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Memphis&slctState=TN" className="hover:text-red-600">Memphis Travel Agents</a></p>

                                        </div>
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Miami-Ft.+Lauderdale&slctState=FL" className="hover:text-red-600">Miami Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Milwaukee&slctState=WI" className="hover:text-red-600">Milwaukee Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Minneapolis-St.+Paul&slctState=MN" className="hover:text-red-600">Minneapolis Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Montreal&slctState=QC" className="hover:text-red-600">Montreal Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=New+York&slctState=NY" className="hover:text-red-600">New York Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Orlando-Daytona+Beach-Melbourne&slctState=FL" className="hover:text-red-600">Orlando Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Ottawa&slctState=ON" className="hover:text-red-600">Ottawa Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Phoenix&slctState=AZ" className="hover:text-red-600">Phoenix Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Portland&slctState=OR" className="hover:text-red-600">Portland Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Toronto&slctState=ON" className="hover:text-red-600">Toronto Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Vancouver&slctState=BC" className="hover:text-red-600">Vancouver Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctMetro=Winnipeg&slctState=MB" className="hover:text-red-600">Winnipeg Travel Agents</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/find_travel_agent#metro" className="hover:text-red-600 font-bold text-xs">View more metro areas &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide text-sm">Agents by Interest</h3>
                                    <div className="md:grid grid-cols-1">
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Adult+Only" className="hover:text-red-600">Adult Only Vacation Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=All+Inclusive" className="hover:text-red-600">All Inclusive Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Beach+Vacations" className="hover:text-red-600">Beach Vacation Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Couples+%26+Romance" className="hover:text-red-600">Couples &amp; romance Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Destination+Weddings" className="hover:text-red-600">Destination Wedding Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Escorted+Tours" className="hover:text-red-600">Escorted Tour Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Expedition+Cruises" className="hover:text-red-600">Expedition Cruise Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Family+Vacations" className="hover:text-red-600">Family Vacation Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Honeymoons" className="hover:text-red-600">Honeymoons Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Luxury+Cruises" className="hover:text-red-600">Luxury Cruise Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=Luxury" className="hover:text-red-600">Luxury Travel Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctInterest=River+Cruises" className="hover:text-red-600">River Cruise Specialists</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/find_travel_agent#interest" className="hover:text-red-600 font-bold text-xs">View more &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide text-sm">Agents by Destination</h3>
                                    <div className="md:grid grid-cols-2 gap-2">
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Africa&destinationId=62221" className=" hover:text-red-600">Africa Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Alaska&destinationId=62468" className="hover:text-red-600">Alaska Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Asia&destinationId=900023" className="hover:text-red-600">Asia Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Australia&destinationId=62294" className="hover:text-red-600">Australia Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Bahamas&destinationId=62321" className="hover:text-red-600">Bahamas Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Cabo+San+Lucas&destinationId=62515" className="hover:text-red-600">Cabo San Lucas Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Cancun&destinationId=60027" className="hover:text-red-600">Cancun Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Caribbean&destinationId=62224" className="hover:text-red-600">Caribbean Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Europe&destinationId=62227" className="hover:text-red-600">Europe Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Florida&destinationId=62478" className="hover:text-red-600">Florida Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Greece&destinationId=62410" className="hover:text-red-600">Greece Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Hawaii&destinationId=62469" className="hover:text-red-600">Hawaii Experts</a></p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Italy&destinationId=62396" className="hover:text-red-600">Italy Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Jamaica&destinationId=62320" className="hover:text-red-600">Jamaica Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Japan&destinationId=62355" className="hover:text-red-600">Japan Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Mexico&destinationId=106337" className="hover:text-red-600">Mexico Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Middle+East&destinationId=62228" className="hover:text-red-600">Middle East Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=New+Zealand&destinationId=62318" className="hover:text-red-600">New Zealand Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Portugal&destinationId=62391" className="hover:text-red-600">Portugal Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Punta+Cana&destinationId=61703" className="hover:text-red-600">Punta Cana Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=South+America&destinationId=62231" className="hover:text-red-600">South America Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=South+Pacific&destinationId=106339" className="hover:text-red-600">South Pacific Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Spain&destinationId=62390" className="hover:text-red-600">Spain Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/agents?slctDestination=Thailand&destinationId=62359" className="hover:text-red-600">Thailand Experts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/find_travel_agent#destination" className="hover:text-red-600 font-bold text-xs">View more &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide text-sm">Travel Specials</h3>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotion_category?category=cruise" className="hover:text-red-600">Cruises</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotion_category?category=hotel" className="hover:text-red-600">Hotels &amp; resorts</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotion_category?category=tour" className="hover:text-red-600">Vacation packages</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotion_category?category=car" className="hover:text-red-600">Car packages</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotion_category?category=rail" className="hover:text-red-600">Rail packages</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/inspiration" className="hover:text-red-600">Travel inspiration</a></p>
                                            <p className="p-0 mx-0 my-3 leading-none"><a href="/promotions" className="hover:text-red-600 font-bold text-xs">View all specials &gt;</a></p>
                                        </div>
                                    </div>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <p className="p-0 mx-0 mt-6 md:mt-8 leading-none">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openAxeptioCookies();
                                                }}
                                            >
                                                Manage Cookie Preferences
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;
