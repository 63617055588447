/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect } from 'react';
import API from '../services/API';

const AppContext = createContext([{}, () => { }]);

const AppContextProvider = (props) => {
    const [appState, setAppState] = useState({});

    const fetchLookups = async () => Promise.all(
        [
            API.fetchStates().then((response) => response),
            API.fetchInterests().then((response) => response),
            API.fetchDestinations().then((response) => response),
            API.getPopularDestinations().then((response) => response),
            API.getBlogInterests().then((response) => response),
            API.getBlogDestinations().then((response) => response),
            API.getBlogSuppliers().then((response) => response),
            API.getBlogCategories().then((response) => response),
            API.fetchAgentCount().then((response) => response),
            API.fetchSuppliers().then((response) => response),
            API.fetchLanguages().then((response) => response),
        ],

    );

    useEffect(() => {
        fetchLookups().then((result) => {
            //if (result[0].error) alert(`Error - ${result[0].error.message}`);
            setAppState(
                {
                    ...appState,
                    states: result[0].error ? [] : result[0],
                    specialities: result[1].error ? [] : result[1],
                    destinations: result[2].error ? [] : result[2],
                    popularDestinations: result[3].error ? [] : result[3],
                    blogInterests: result[4].error ? [] : result[4],
                    blogDestinations: result[5].error ? [] : result[5],
                    blogSuppliers: result[6].error ? [] : result[6],
                    blogCategories: result[7].error ? [] : result[7],
                    agentCount: result[8].error ? [] : result[8],
                    suppliers: result[9].error ? [] : result[9],
                    languages: result[10].error ? [] : result[10],
                },
            );
        });
    }, []);

    return (
        <AppContext.Provider value={[appState, setAppState]}>
            {props.children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };
