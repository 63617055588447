import React from 'react';
import PropTypes from 'prop-types';

function AgentAffiliation({
    icAgent,
    agencyLevel,
    agencyRecordType,
    icBusinnessName,
    agencyName,
    hostAgency,
    securityLevel,
    hostCity,
    hostState,
}) {
    let icMessage;
    let displayHostAgency = hostAgency;
    if (hostCity !== '') {
        displayHostAgency = `${hostCity}, ${hostState}`;
    }
    if (icAgent === '1') {
        /*ASSOCIATE IC*/
        if ((agencyRecordType === 'Associate') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with Travel Leaders ${displayHostAgency}`;
        }
        if ((agencyRecordType === 'Associate') && (icBusinnessName === null)) {
            icMessage = `An independent agent with Travel Leaders ${displayHostAgency}`;
        }
        /*AFFILLIATE US IC*/
        if ((agencyRecordType === 'Affiliate_US') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with ${agencyName}, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Affiliate_US') && (icBusinnessName === null)) {
            icMessage = `An independent agent with ${agencyName}, , a Travel Leaders Network Affiliate Agency`;
        }
        /*AFFILLIATE CA IC*/
        if ((agencyRecordType === 'Affiliate_CA') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with ${agencyName}, a Travel Leaders Network Affiliate Agency `;
        }
        if ((agencyRecordType === 'Affiliate_CA') && (icBusinnessName === null)) {
            icMessage = `An independent agent with ${agencyName}, a Travel Leaders Network Affiliate Agency`;
        }
        /*NEXION US*/
        if ((agencyRecordType === 'Nexion US') && (icBusinnessName !== null) && (securityLevel === 'AgencyAdmin')) {
            icMessage = `${icBusinnessName}, an independent agent with Nexion, LLC, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Nexion US') && (icBusinnessName === null) && (securityLevel === 'AgencyAdmin')) {
            icMessage = `${hostAgency}, an independent agent with Nexion, LLC, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Nexion US') && (securityLevel !== 'AgencyAdmin')) {
            icMessage = `${hostAgency}, an independent agent with Nexion, LLC, a Travel Leaders Network Affiliate Agency`;
        }
        /*NEXION CA*/
        if ((agencyRecordType === 'Nexion CA') && (icBusinnessName !== null) && (securityLevel === 'AgencyAdmin')) {
            icMessage = `${icBusinnessName}, an independent agent with Nexion CA, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Nexion CA') && (icBusinnessName === null) && (securityLevel === 'AgencyAdmin')) {
            icMessage = `${hostAgency}, an independent agent with Nexion CA, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Nexion CA') && (securityLevel !== 'AgencyAdmin')) {
            icMessage = `${hostAgency}, an independent agent with Nexion CA, a Travel Leaders Network Affiliate Agency`;
        }
        /*AVOYA US*/
        if ((agencyRecordType === 'Avoya US') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Avoya US') && (icBusinnessName === null)) {
            icMessage = 'An independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency';
        }
        /*AVOYA CA*/
        if ((agencyRecordType === 'Avoya CA') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Avoya CA') && (icBusinnessName === null)) {
            icMessage = 'An independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency';
        }
        /*TTAND*/
        if ((agencyRecordType === 'TTAND') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with The Travel Agent Next Door Inc., a Travel Leaders Network Affiliate Agency`;
        }
        if (((agencyRecordType === 'TTAND')) && (icBusinnessName === null)) {
            icMessage = `${hostAgency}, an independent agent with The Travel Agent Next Door Inc., a Travel Leaders Network Affiliate Agency`;
        }
        /*WORLDVIA*/
        if ((agencyName.toLowerCase().includes('worldvia') || (agencyName.toLowerCase().includes('travel quest network'))) && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with WorldVia, a Travel Leaders Agency`;
        }
        if ((agencyName.toLowerCase().includes('worldvia') || (agencyName.toLowerCase().includes('travel quest network'))) && (icBusinnessName === null)) {
            icMessage = 'An independent agent with WorldVia, a Travel Leaders Agency';
        }
    } else if (icAgent === '0') {
        if ((agencyLevel === 'Enterprise') || (agencyLevel === 'Executive') || (agencyLevel === 'Professional') || (agencyLevel === 'Legacy')) {
            icMessage = 'A full-service Travel Leaders Associate Agency';
        }
        if ((agencyLevel === 'Affiliate') || (agencyLevel === 'Results') || (agencyLevel === 'Cruise holidays') || (agencyRecordType === 'TTAND')) {
            icMessage = `${agencyName}, a Travel Leaders Network Affiliate Agency`;
        }
        /*AVOYA US*/
        if ((agencyRecordType === 'Avoya US') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Avoya US') && (icBusinnessName === null)) {
            icMessage = 'An independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency';
        }
        /*AVOYA CA*/
        if ((agencyRecordType === 'Avoya CA') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency`;
        }
        if ((agencyRecordType === 'Avoya CA') && (icBusinnessName === null)) {
            icMessage = 'An independent agent with Avoya Travel, a Travel Leaders Network Affiliate Agency';
        }
        /*TTAND*/
        if ((agencyRecordType === 'TTAND') && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with The Travel Agent Next Door Inc., a Travel Leaders Network Affiliate Agency`;
        }
        if (((agencyRecordType === 'TTAND')) && (icBusinnessName === null)) {
            icMessage = 'An independent agent with The Travel Agent Next Door Inc., a Travel Leaders Network Affiliate Agency';
        }
        /*WORLDVIA*/
        if ((agencyName.toLowerCase().includes('worldvia') || (agencyName.toLowerCase().includes('travel quest network'))) && (icBusinnessName !== null)) {
            icMessage = `${icBusinnessName}, an independent agent with WorldVia, a Travel Leaders Agency`;
        }
        if ((agencyName.toLowerCase().includes('worldvia') || (agencyName.toLowerCase().includes('travel quest network'))) && (icBusinnessName === null)) {
            icMessage = 'An independent agent with WorldVia, a Travel Leaders Agency';
        }
    }

    return (
        <>
            <div className="text-xs">
                {icMessage}
            </div>
        </>
    );
}

AgentAffiliation.propTypes = {
    icAgent: PropTypes.string.isRequired,
    agencyLevel: PropTypes.string,
    agencyRecordType: PropTypes.string,
    icBusinnessName: PropTypes.string,
    agencyName: PropTypes.string,
    hostAgency: PropTypes.string,
    securityLevel: PropTypes.string,
    hostCity: PropTypes.string,
    hostState: PropTypes.string,
};

AgentAffiliation.defaultProps = {
    agencyLevel: '',
    agencyRecordType: '',
    icBusinnessName: '',
    hostAgency: '',
    agencyName: '',
    securityLevel: '',
    hostCity: '',
    hostState: '',

};

export default AgentAffiliation;
