/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
import React, {
    useEffect, useState, useRef,
} from 'react';
import {
    IoClose,
    IoChevronBack,
} from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';
import Modal from 'react-modal';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html-react';
import ReactImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player';
import Skeleton from 'react-loading-skeleton';
import { Slide } from 'react-slideshow-image';
import { getStateName, modalCustomStyles, truncateString } from '../utils';
import API from '../services/API';
import ListItem from '../components/elements/ListItem';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';

const ModalTypes = ['destinations', 'interests', 'suppliers', 'album', 'video', 'travel'];

function Story() {
    const { id, bioId, storyId } = useParams();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState();
    const [albums, setAlbums] = useState([]);
    const [selectedAlbumId, setSelectedAlbumId] = useState();
    const [videos, setVideos] = useState([]);
    const [modalType, setModalType] = useState('');
    const history = useHistory();
    const reviewsRef = useRef(null);
    const myAlbumRef = useRef(null);
    const [story, setStory] = useState();
    const [links, setLinks] = useState([]);
    const [bio, setBio] = useState();

    const executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,
        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    getTravelStory();
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    const closeModal = () => {
        setModalType('');
    };

    const loader = () => (
        <div className="max-w-screen-2x px-4 min-h-screen ">
            <div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={400} />
                </div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={400} />
                </div>
            </div>
        </div>
    );

    const getTravelStory = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId: 0,
            blogId: storyId,
            returnAll: false,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStory(result.data[0]);
                getAgentAlbum();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAdditionalBio = () => {
        setLoading(true);
        API.additionalBio({
            agentId: id,
            bioId,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setBio(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentAlbum = () => {
        setLoading(true);
        API.agentAlbum({
            agentId: id,
            contentId: storyId,
            contentType: 'exp',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setAlbums(result.data);
                getAgentVideos();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentVideos = () => {
        setLoading(true);
        API.agentVideos({
            agentId: id,
            contentId: storyId,
            contentType: 'exp',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setVideos(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (agent && story) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${id}`,
            }];

            if (bioId) {
                base.push(
                    {
                        label: `${bio.displayTitle}`,
                        url: `/agent/${id}/bio/${bioId}`,
                    },
                );
            }
            setLinks([...base, {
                label: `${story.postTitle}`,
            }]);
        }
    }, [agent, story]);

    useEffect(() => {
        if (bioId) {
            getAdditionalBio();
        }
    }, [bioId]);

    const agentBio = () => (
        <div className="flex flex-col gap-4">
            <BreadCrumb links={links} />
            <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => history.push(`/agent/${id}`)}>
                <IoChevronBack className=" text-right" />
                <div>
                    {' '}
                  Return to My Main profile
                </div>
            </div>
            <AgentLabel
                agent={agent}
                customMetaTitle={story && (story.postTitle)}
                customMetaDescription={story && (truncateString(sanitizeHtml(story.postText, { allowedTags: [], allowedAttributes: {} })))}
            />

            {story && (
                <div className="flex flex-col gap-2 px-4  md:px-0 md:py-4">
                    <div className="text-center md:text-left text-2xl font-medium uppercase">{story.postTitle}</div>
                    <div className="pure-u-1">
                        <div className="text-gray-700 text-sm md:text-xl py-2">
                            <div className="float-left mr-4 pure-g mt-4">
                                <img src={story.travelImage} className="w-96 " alt={story.travelImageCaption} loading="lazy" />
                            </div>
                            {parser(sanitizeHtml(story.postText))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const agentTravelAlbums = () => (
        <div ref={myAlbumRef}>
            {(albums.length > 0 || videos.length > 0) && (
                <div className="flex flex-col gap-2 py-8 md:px-10 -mx-4 md:-mx-20 items-center justify-center bg-light-blue">
                    <div className="text-center px-10 md:text-left md:px-0 text-2xl font-medium uppercase">My Photo Albums and Videos</div>
                    {/*Destktop Display*/}
                    <div className="hidden md:grid md:grid-cols-3 md:gap-4 mt-10">
                        {albums.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 pb-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.albumId}
                                onClick={() => {
                                    setSelectedAlbumId(x.albumId);
                                    setModalType('album');
                                }}
                            >
                                <div className="flex  w-full md:w-96 overflow-hidden">
                                    <img
                                        src={x.albumImages[0].image}
                                        className="w-full md:w-96 h-56 mx-auto object-cover"
                                        alt=""
                                        loading="lazy"
                                    />
                                    {' '}
                                </div>

                                <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                    <div className="w-full md:w-2/3 text-center"><span className="tl-cyan text-lg font-medium">{x.albumTitle}</span></div>
                                    <div className="w-full md:w-1/3 text-center">
                                        <div className="tl-red cursor-pointer font-semibold uppercase hover:text-black">
                                            View Album
                                            <i className="fa fa-caret-right" aria-hidden="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                        {videos.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.videoId}
                            >
                                <div className="flex  md:w-96 h-44 md:h-56 overflow-hidden mx-auto bg-black">
                                    <ReactPlayer
                                        controls
                                        url={x.videoURL}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>

                                <div className="flex">
                                    <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                </div>
                            </div>

                        ))}
                    </div>
                    {/*Mobile Display*/}
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={5000}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {albums.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 pb-4"
                                    key={x.albumId}
                                    onClick={() => {
                                        setSelectedAlbumId(x.albumId);
                                        setModalType('album');
                                    }}
                                >
                                    <div className="flex  w-full md:w-96 overflow-hidden">
                                        <img
                                            src={x.albumImages[0].image}
                                            className="w-full md:w-96 h-56 mx-auto object-cover"
                                            alt=""
                                            loading="lazy"
                                        />
                                        {' '}
                                    </div>

                                    <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                        <div className="w-full md:w-2/3 text-center"><span className="tl-cyan text-lg font-medium">{x.albumTitle}</span></div>
                                        <div className="w-full md:w-1/3 text-center">
                                            <div className="tl-red cursor-pointer font-semibold uppercase hover:text-black">
                                                View Album
                                            <i className="fa fa-caret-right" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={7500}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {videos.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 cursor-pointer"
                                    key={x.videoId}
                                >
                                    <div className="flex  md:w-96 h-44 md:h-56 overflow-hidden mx-auto bg-black">
                                        <ReactPlayer
                                            controls
                                            url={x.videoURL}
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>

                                    <div className="flex">
                                        <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    {/*END MOBILE DISPLAY*/}
                </div>
            )}
        </div>
    );

    return (
        <div className=" max-w-screen-2xl mx-auto">
            <ToastContainer />
            {agent ? (
                <div className="h-full  flex flex-col pt-24 md:pt-12 px-4 md:px-10 mx-auto">
                    {agentBio()}
                    {(albums.length > 0 || videos.length > 0) && agentTravelAlbums()}
                    <Modal
                        isOpen={ModalTypes.some((x) => x === modalType)}
                        onRequestClose={() => closeModal()}
                        style={modalCustomStyles}
                        contentLabel="Modal"
                        ariaHideApp={false}
                    >
                        <div className="max-w-6xl">
                            <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                                <IoClose className="text-red-500 text-right" />
                            </div>
                            <div className=" px-4">
                                {modalType === 'interests'
                                    && (
                                        <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                            {agent.agentInterests.map((x) => (
                                                <div
                                                    key={x.interestName}
                                                    onClick={() => history.push(`/agents?slctInterest=${x.interestName}&agentId=${agent.agentId}`)}
                                                >

                                                    <ListItem title={x.interestName} isClickable />
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                {modalType === 'destinations'
                                    && (
                                        <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                            {agent.agentDestinations.map((x) => (
                                                <div
                                                    key={x.locationId}
                                                    onClick={() => history.push(`/agents?slctDestination=${x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}&destinationId=${x.locationId}&agentId=${agent.agentId}`)}
                                                >
                                                    <ListItem
                                                        title={x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}
                                                        isClickable
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                {modalType === 'suppliers'
                                    && (
                                        <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                            {agent.agentSuppliers.map((x) => (
                                                <div
                                                    key={x.supplierName}
                                                >
                                                    <ListItem title={x.supplierName} />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                {modalType === 'album'
                                    && (
                                        <div>
                                            <div className="text-red-700 text-xl font-medium text-center mb-6">
                                                {albums.find((x) => x.albumId === selectedAlbumId).albumTitle}
                                            </div>
                                            <ReactImageGallery
                                            items={albums.find((x) => x.albumId === selectedAlbumId)
                                                .albumImages.map((y) => ({
                                                    original: y.image,
                                                    thumbnail: y.imageThumb,
                                                    originalTitle: y.imageCaption,
                                                    originalWidth: 100,
                                                    description: y.imageCaption,
                                                }))}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : loader()}
            {loading && <Loading />}
        </div>
    );
}

export default Story;
