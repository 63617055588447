/* eslint-disable no-shadow */
import { useContext, useState } from 'react';
import { AppContext } from './AppContext';

const useAppStore = () => {
    const [appState, setAppState] = useContext(AppContext);
    const [isEmailFormModalOpen, setIsEmailFormModalOpen] = useState(false);
    const [isPhoneFormModalOpen, setIsPhoneFormModalOpen] = useState(false);
    const [isAppointmentFormModalOpen, setIsAppointmentFormModalOpen] = useState(false);
    const [isEmergencyFromModalOpen, setIsEmergencyFromModalOpen] = useState(false);

    const closeFormModal = () => {
        setIsEmailFormModalOpen(false);
        setIsPhoneFormModalOpen(false);
        setIsAppointmentFormModalOpen(false);
        setIsEmergencyFromModalOpen(false);
    };

    const setStates = (states) => {
        setAppState((appState) => ({
            ...appState,
            states,
        }));
    };

    const setAgentCount = (agentCount) => {
        setAppState((appState) => ({
            ...appState,
            agentCount,
        }));
    };

    const setSpecialities = (specialities) => {
        setAppState((appState) => ({
            ...appState,
            specialities,
        }));
    };

    const setSuppliers = (suppliers) => {
        setAppState((appState) => ({
            ...appState,
            suppliers,
        }));
    };

    const setLanguages = (languages) => {
        setAppState((appState) => ({
            ...appState,
            languages,
        }));
    };

    const setBlogInterests = (blogInterests) => {
        setAppState((appState) => ({
            ...appState,
            blogInterests,
        }));
    };

    const setBlogDestinations = (blogDestinations) => {
        setAppState((appState) => ({
            ...appState,
            blogDestinations,
        }));
    };

    const setBlogSuppliers = (blogSuppliers) => {
        setAppState((appState) => ({
            ...appState,
            blogSuppliers,
        }));
    };

    const setBlogCategories = (blogCategories) => {
        setAppState((appState) => ({
            ...appState,
            blogCategories,
        }));
    };

    const setDestinations = (destinations) => {
        setAppState((appState) => ({
            ...appState,
            destinations,
        }));
    };

    function getStates() {
        return appState.states || [];
    }

    function getAgentCount() {
        return appState.agentCount || [];
    }

    function getSpecialities() {
        return appState.specialities || [];
    }

    function getSuppliers() {
        return appState.suppliers || [];
    }

    function getLanguages() {
        return appState.languages || [];
    }

    const setFetchingAgents = (val) => {
        setAppState((appState) => ({
            ...appState,
            fetchingAgents: val,
        }));
    };

    const isFetchingAgent = () => appState.fetchingAgents;

    const setAgents = (agents, clearPrevious = true) => {
        if (clearPrevious) {
            setAppState((appState) => ({
                ...appState,
                agents,
                fetchingAgents: false,
            }));
        } else {
            const agentList = appState.agents
                ? appState.agents.agent.concat(agents.agent) : agents.agent;
            setAppState((appState) => ({
                ...appState,
                agents: {
                    agent: agentList,
                    currentAgentCount: agents.currentAgentCount,
                    totalAgents: agents.totalAgents,

                },
                fetchingAgents: false,
            }));
        }
    };

    const setSelectedState = (val) => {
        setAppState((appState) => ({
            ...appState,
            selectedState: val,
        }));
    };

    const selectedState = () => appState.selectedState || '';

    const setSelectedInterest = (val) => {
        setAppState((appState) => ({
            ...appState,
            selectedInterest: val,
        }));
    };

    const selectedInterest = () => appState.selectedInterest || '';

    const setSelectedDestination = (val) => {
        setAppState((appState) => ({
            ...appState,
            selectedDestination: val,
        }));
    };

    const selectedDestination = () => appState.selectedDestination || '';

    const setSelectedZipCode = (val) => {
        setAppState((appState) => ({
            ...appState,
            selectedZipCode: val,
        }));
    };

    const setSelectedAgentName = (val) => {
        setAppState((appState) => ({
            ...appState,
            selectedAgentName: val,
        }));
    };

    return {
        setStates,
        getStates,
        setAgentCount,
        getAgentCount,
        setSpecialities,
        getSpecialities,
        setSuppliers,
        getSuppliers,
        setLanguages,
        getLanguages,
        setBlogInterests,
        getBlogInterests: appState.blogInterests || [],
        setBlogDestinations,
        getBlogDestinations: appState.blogDestinations || [],
        setBlogSuppliers,
        getBlogSuppliers: appState.blogSuppliers || [],
        setBlogCategories,
        getBlogCategories: appState.blogCategories || [],
        setDestinations,
        getDestinations: appState.destinations || [],
        popularDestinations: appState.popularDestinations || [],
        setFetchingAgents,
        isFetchingAgent,
        setAgents,
        getAgents: appState.agents || { agent: [] },
        selectedAgentName: appState.selectedAgentName || '',
        selectedDestination,
        selectedInterest,
        selectedState,
        selectedZipCode: appState.selectedZipCode || '',
        setSelectedAgentName,
        setSelectedDestination,
        setSelectedInterest,
        setSelectedState,
        setSelectedZipCode,

        isAppointmentFormModalOpen,
        isEmailFormModalOpen,
        isEmergencyFromModalOpen,
        isPhoneFormModalOpen,

        setIsAppointmentFormModalOpen,
        setIsEmailFormModalOpen,
        setIsEmergencyFromModalOpen,
        setIsPhoneFormModalOpen,
        closeFormModal,
    };
};

export default useAppStore;
